<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Cash">
        <template #subTitle>
          <a-input
            v-model:value="searchValue"
            @change="onHandleSearch"
            placeholder="Search by Name"
          >
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template>
        <template #buttons>
          <sdButton
            @click="showModal"
            class="btn-add_new"
            cash="default"
            type="primary"
            key="1"
          >
            <a to="#">+ Add New</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Cash">
          <div v-if="isLoading" class="spin">
            <a-spin />
          </div>

          <div v-else>
            <TableWrapper class="table-data-view table-responsive">
              <a-table
                @change="onHandleTableChange"
                :pagination="pagination"
                :dataSource="dataSource"
                :columns="columns"
              />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <sdModal
        title="Cash's information"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form
                name="sDash_validation-form"
                ref="formRef"
                @finish="onSubmit"
                :model="formState"
                :rules="rules"
                :layout="formState.layout"
              >
                <a-row :gutter="25">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="name" label="Account name" name="name">
                      <a-input
                        size="large"
                        v-model:value="formState.name"
                        placeholder="Cash name"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item
                      ref="initialBalance"
                      label="Initial Balance"
                      name="initialBalance"
                    >
                      <a-input-number
                        :style="{ width: '100%' }"
                        size="small"
                        v-model:value="formState.initialBalance"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" :xs="24">
                    <a-form-item
                      name="dateInitialBalance"
                      label="Date Initial Balance"
                      ref="dateInitialBalance"
                    >
                      <a-date-picker
                        v-model:value="formState.dateInitialBalance"
                        :style="{ width: '100%' }"
                        :format="dateFormat"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <sdButton
                      htmlType="submit"
                      cash="full"
                      type="primary"
                      key="submit"
                    >
                      <span v-if="!formState.editionMode">Add new</span>
                      <span v-if="formState.editionMode">Edit cash</span>
                    </sdButton>
                  </a-col>
                </a-row>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";
import moment from "moment";

const dateFormat = "YYYY/MM/DD";

const columns = [
  {
    title: "Cash",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Initial Balance",
    dataIndex: "initialBalance",
    key: "initialBalance",
  },
  {
    title: "DateInitial Balance",
    dataIndex: "dateInitialBalance",
    key: "dateInitialBalance",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Cash = {
  name: "Cash",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const CashApi = computed(() => state.CashApi.data.data);
    const isLoading = computed(() => state.CashApi.loading);
    const searchValue = ref("");
    var loading = false;
    var cashPagination = computed(() => state.CashApi.data);
    var sortDirections = "ascend";

    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });

    const formRef = ref();
    const formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      initialBalance: "",
      dateInitialBalance: "",
    });

    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
        {
          min: 2,
          max: 100,
          message: "Field need to be longer then two and shorter then 100",
          trigger: "change",
        },
      ],
      initialBalance: [
        {
          type: "number",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      dateInitialBalance: [
        {
          type: "object",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("cashGetPagedData", pagination.value);
      formState.editionMode = false;
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("cashGetPagedData", pagination);
    };
    var pagination = computed(() =>
      cashPagination.value && cashPagination.value.succeeded == true
        ? {
            current: cashPagination.value.pageNumber,
            total: searchValue.value
              ? cashPagination.value.recordsFiltered
              : cashPagination.value.recordsTotal,
            pageSize: cashPagination.value.pageSize,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
        : {
            current: 1,
            pageSize: 10,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
    );
    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("cashApiDataDelete", { id });
      }
      return false;
    };

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("cashGetPagedData", pagination);
    };

    const dataSource = computed(() =>
      CashApi.value && CashApi.value.length
        ? CashApi.value.map((cash, key) => {
            const { id, name, initialBalance, dateInitialBalance } = cash;

            return {
              key: key + 1,
              name,
              initialBalance,
              dateInitialBalance: moment(dateInitialBalance).format(dateFormat),
              action: (
                <div class="table-actions">
                  <a-button class="edit" onClick={() => handleEdit(cash)}>
                    <sdFeatherIcons type="edit" cash={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button class="delete" onClick={() => handleDelete(id)}>
                    <sdFeatherIcons type="trash-2" cash={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!formState.editionMode) {
            dispatch("cashSubmitData", {
              formState,
            });
          } else {
            dispatch("cashUpdateData", {
              formState,
            });
          }
          await nextTick();
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.name = "";
      formState.initialBalance = "";
      formState.dateInitialBalance = "";
      localState.visible = true;
    };
    const handleEdit = (cash) => {
      formState.editionMode = true;
      formState.id = cash.id;
      formState.name = cash.name;
      formState.initialBalance = cash.initialBalance;
      formState.dateInitialBalance = moment(cash.dateInitialBalance);
      localState.visible = true;
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };

    return {
      CashApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      localState,
      handleDelete,
      dataSource,
      columns,
      onHandleSearch,
      loading,
      sortDirections,
      formState,
      searchValue,
      cashPagination,
      onHandleTableChange,
      pagination,
      dateFormat,
      showModal,
      handleCancel,
    };
  },
};

export default Cash;
</script>
<style lang="scss">
.sDash_form-action {
  display: flex;
  justify-content: space-between;
}
</style>
